export default {
  methods: {
    filterPrice(price) {
      let value = {value: '1.00 € - 250.00 €', label: 54};
      if (price < 251) {
        value = {value: '1.00 € - 250.00 €', label: 54};
      } else if (price < 501) {
        value = {value: '251.00 € - 500.00 €', label: 65};
      } else if (price < 751) {
        value = {value: '501.00 € - 750.00 €', label: 66};
      } else if (price < 1001) {
        value = {value: '751.00 € - 1,000.00 €', label: 66};
      } else if (price < 1251) {
        value = {value: '1,001.00 € - 1,250.00 €', label: 66};
      } else if (price < 1501) {
        value = {value: '1,251.00 € - 1,500.00 €', label: 72};
      } else if (price < 1751) {
        value = {value: '1,501.00 € - 1,750.00 €', label: 72};
      } else if (price < 2001) {
        value = {value: '1,751.00 € - 2,000.00 €', label: 77};
      } else if (price < 2501) {
        value = {value: '2,001.00 € - 2,500.00 €', label: 85};
      } else if (price < 3001) {
        value = {value: '2,501.00 € - 3,000.00 €', label: 93};
      } else if (price < 3501) {
        value = {value: '3,001.00 € - 3,500.00 €', label: 118};
      } else if (price < 4001) {
        value = {value: '3,501.00 € - 4,000.00 €', label: 125};
      } else if (price < 4501) {
        value = {value: '4,001.00 € - 4,500.00 €', label: 152};
      } else if (price < 5001) {
        value = {value: '4,501.00 € - 5,000.00 €', label: 165};
      } else if (price < 5501) {
        value = {value: '5,001.00 € - 5,500.00 €', label: 176};
      } else if (price < 6001) {
        value = {value: '5,501.00 € - 6,000.00 €', label: 182};
      } else if (price < 6501) {
        value = {value: '6,001.00 € - 6,500.00 €', label: 213};
      } else if (price < 7001) {
        value = {value: '6,501.00 € - 7,000.00 €', label: 214};
      } else if (price < 7501) {
        value = {value: '7,001.00 € - 7,500.00 €', label: 242};
      } else if (price < 8001) {
        value = {value: '7,501.00 € - 8,000.00 €', label: 244};
      } else if (price < 8501) {
        value = {value: '8,001.00 € - 8,500.00 €', label: 273};
      } else if (price < 9001) {
        value = {value: '8,501.00 € - 9,000.00 €', label: 273};
      } else if (price > 9001) {
        value = {value: 'ab 9,001.00 €', label: 303};
      }
      return value;
    },
  },
  computed: {
    prices () {
      return [
        {value: '1.00 € - 250.00 €', label: 54},
        {value: '251.00 € - 500.00 €', label: 65},
        {value: '501.00 € - 750.00 €', label: 66},
        {value: '751.00 € - 1,000.00 €', label: 66},
        {value: '1,001.00 € - 1,250.00 €', label: 66},
        {value: '1,251.00 € - 1,500.00 €', label: 72},
        {value: '1,501.00 € - 1,750.00 €', label: 72},
        {value: '1,751.00 € - 2,000.00 €', label: 77},
        {value: '2,001.00 € - 2,500.00 €', label: 85},
        {value: '2,501.00 € - 3,000.00 €', label: 93},
        {value: '3,001.00 € - 3,500.00 €', label: 118},
        {value: '3,501.00 € - 4,000.00 €', label: 125},
        {value: '4,001.00 € - 4,500.00 €', label: 152},
        {value: '4,501.00 € - 5,000.00 €', label: 165},
        {value: '5,001.00 € - 5,500.00 €', label: 176},
        {value: '5,501.00 € - 6,000.00 €', label: 182},
        {value: '6,001.00 € - 6,500.00 €', label: 213},
        {value: '6,501.00 € - 7,000.00 €', label: 214},
        {value: '7,001.00 € - 7,500.00 €', label: 242},
        {value: '7,501.00 € - 8,000.00 €', label: 244},
        {value: '8,001.00 € - 8,500.00 €', label: 273},
        {value: '8,501.00 € - 9,000.00 €', label: 273},
        {value: 'ab 9,001.00 €', label: 303},
      ];
    },
  }
}
